<template>
    <div class="app-container invoice-account">
        <nav>
            <div class="tips">
                <el-popover placement="bottom-start" width="3rem" trigger="click" popper-class="myPopover" v-model="visible">
                <el-form :inline="true" :model="queryForm" ref="queryForm" label-width="90px" >
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="创建日期"  prop="startCreateTime">
                                <el-date-picker
                                v-model="queryForm.startCreateTime"
                                type="date"
                                placeholder="开始日期"
                                format="yyyy年MM月dd日"
                                value-format="yyyy-MM-dd"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" >
                            <el-form-item label="创建截至"  prop="endCreateTime">
                                <el-date-picker
                                v-model="queryForm.endCreateTime"
                                type="date"
                                placeholder="结束日期"
                                format="yyyy年MM月dd日"
                                value-format="yyyy-MM-dd"
                            />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="开票日期"  prop="startKprq">
                                <el-date-picker
                                v-model="queryForm.startKprq"
                                type="date"
                                placeholder="开始日期"
                                format="yyyy年MM月dd日"
                                value-format="yyyy-MM-dd"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" >
                            <el-form-item label="开票截至"  prop="endKprq">
                                <el-date-picker
                                v-model="queryForm.endKprq"
                                type="date"
                                placeholder="结束日期"
                                format="yyyy年MM月dd日"
                                value-format="yyyy-MM-dd"
                            />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="发票代码" prop="fpdm"  class="single_query">
                            <el-input placeholder="请输入发票代码" v-model="queryForm.fpdm" maxlength="50" class="filter-item" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="发票号码" prop="fphm"  class="single_query">
                            <el-input placeholder="请输入发票号码" v-model="queryForm.fphm" maxlength="50" class="filter-item" />
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="交款人" prop="jkr"  class="single_query">
                            <el-input placeholder="请输入交款人" v-model="queryForm.jkr" maxlength="50" class="filter-item" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="医院名称" prop="skdw"  class="single_query">
                            <el-input placeholder="请输入医院名称" v-model="queryForm.skdw" maxlength="50" class="filter-item" />
                        </el-form-item>
                        </el-col>
                    </el-row>
                    
                    <el-row >
                        <el-form-item >
                        <el-button v-waves class="btn" type="primary" icon="el-icon-search" @click="handleQuery" ref="search" v-auth='"invoiceAccountIncome:lookup"'>搜索</el-button>
                        <el-button v-waves class="btn" type="primary" icon="el-icon-refresh" @click="handleReset()" v-auth='"invoiceAccountIncome:lookup"'>重置</el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
                <el-button type="primary" class="queryTime" slot="reference">{{queryTime}}<i class="el-icon-caret-bottom"></i>
                </el-button>
                </el-popover>
            </div>
            <div class="tipsBtn">
                <el-button class="btn primary" style="margin-left: 20px" @click="add" v-auth='"income:medical:import"'>&nbsp;新增</el-button>
                <el-button class="btn" @click="batchJkr" v-auth='"income:medical:import"'>&nbsp;交款人</el-button>
                <el-button class="btn"  @click="imports" v-auth='"income:medical:import"'>&nbsp;发票导入</el-button>
                <el-button class="btn" @click="batchExport" v-auth='"invoiceAccountIncome:export"'>&nbsp;发票导出</el-button>
                <el-button class="btn" @click="batchDelete" v-auth='"invoiceAccountIncome:delete"'>&nbsp;批量删除</el-button>
            </div>
        </nav>
        <div class="table">
            <comps-table :tableData="tableData" :tableHeader="tableHeader" v-loading='isLoading' :hideSinglePage='true' class="comp_table" v-if="hasData"
                        :selectionType='true' @multipleSelection='selection' :total='total' :pageSize='queryForm.rows' @current-events="handleCurrentChange" @size-events="changeSize" :pageSizeList='pageSizeList' :page='queryForm.page' height="84%">
            </comps-table>
            <nodata v-else message="暂无数据" class="no_data" v-loading="isLoading" />
        </div>
        
        <import-dialog :visible='importVis' :cancelDialog='importCancel' :requestFrom="requestFrom" :invoiceType="invoiceType" :isOpenEinv="isOpenEinv"/>
        <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config" width="300px">
          <div class="answer">确认删除吗？</div>
        </del-dialog>
        <del-dialog :visible="batchDelDialog" :cancelDialog="cancelDialog" :confirmDialog="batchConfirmDialog" :isdeling="isdeling" :config="config" width="300px">
          <div class="answer">确认选中的发票删除吗？</div>
        </del-dialog>
        <export-dialog :visible='exportVis' title="医疗发票" @selectType="selectType" :isdeling='isdeling' :confirmDialog='confirmExport' :cancelDialog='exportCancel' ref="export"/>
        <medical-drawer ref="medicalForm" :vis="medicalDrawerVis" :cancelDrawer="cancelDrawer"></medical-drawer>
    </div>
</template>
<script>
    import medicalDrawer from "./medicalDrawer.vue";
    import compsTable from "@/components/table/table";
    import importDialog from "@/components/importInvoice/medicalImport.vue"
    import delDialog from '@/components/Dialog'
    import exportDialog from '@/views/income/invoiceAccount/dialog/export'
    import moment from 'moment'
    import axios from 'axios'
    import MedicalDrawer from './medicalDrawer.vue';
    export default {
        components:{compsTable,importDialog,delDialog,exportDialog,MedicalDrawer},
        data() {
            return {
                queryTime:'',
                visible: false,
                exportVis:false,
                exportType:'2',
                isExport:false,
                delDialog:false,
                batchDelDialog:false,
                isdeling:false,
                queryForm:{
                    startCreateTime:moment().month(moment().month()).startOf('month').format('YYYY-MM-DD'),
                    endCreateTime: moment().month(moment().month()).endOf('month').format('YYYY-MM-DD'),
                    startKprq:'',
                    endKprq:'',
                    jkr:'',
                    fpdm:'',
                    fphm:'',
                    skdw:'',
                    page: 1,
                    rows: 20,
                },
                config: {
                  top: '20vh',
                  width: '300px',
                  title: '提示',
                  btnTxt: ["确定", "取消"]
                },
                medicalDrawerVis:false,
                multipleSelection: [],
                requestFrom:"einv",
                invoiceType:"3",
                selRow:'',
                isOpenEinv:"0",
                isLoading: false,
                hasData: true,
                pageSizeList: [20, 50, 100, 200],
                total: 0,
                tableHeader:[
                    { prop: "fpdm", label: "发票代码", center: 'left',width:'120'},
                    { prop: "fphm", label: "发票号码", center: 'left',width:'120'},
                    { prop: "jkr", label: "交款人", center: 'left',width:'120',
                      formatter:(row)=>{
                        if (row.jkr){
                          if (row.xb){
                            return row.jkr+'('+row.xb+')';
                          } else{
                            return row.jkr;
                          }
                        }
                        return '';
                      }
                    },
                    { prop: "skdw", label: "医院名称", center: 'center',width:'120'},
                    { prop: "createTime", label: "创建日期", center: 'left',width:'150'},
                    { prop: "kprq", label: "开票日期", center: 'left',width:'120'},
                    { prop: "jehjdx", label: "金额合计（大写）", center: 'left',width:'120'},
                    { prop: "jehjxx", label: "金额合计（小写）", center: 'right',width:'120'},
                    { prop: "saveType", label: "版式文件", center: 'center',width:'120',
                      render: (h, data) => {
                        return (
                            <span>
                              <el-button class='button'  type="text" onClick={() => { this.downloadFile(data.row);}}>
                                {data.row.saveType}
                              </el-button>
                            </span>
                        );
                      },
                    },
                     { label: "操作",width:'120', center: 'center',
                        render: (h, data) => {
                          return (
                            <span style="display: flex; justify-content: space-around;">
                              <el-button class='button' type="text" v-auth="invoiceAccountIncome:view" onClick={() => { this.edit(data.row);}}>
                                编辑
                              </el-button>
                              <el-button class='button' type="text" v-auth="invoiceAccountIncome:view" onClick={() => { this.delete(data.row);}}>
                                删除
                              </el-button>
                            </span>
                          )
                        },
                      }
                ],
                tableData: [],
                importVis: false,
            }
        },
        methods:{
          cancelDrawer(){
            this.medicalDrawerVis = false;
          },
          downloadFile(row) {
            // 创建一个<a>标签，并设置其href属性为文件的URL地址
            const url = row.filePath
            window.open(url,'_blank');
            // const link = document.createElement('a')
            // link.href = url
            // // 设置下载文件的名称
            // link.download = url.substring(url.lastIndexOf('/') + 1)
            // // 将<a>标签添加到文档中，并模拟点击<a>标签
            // document.body.appendChild(link)
            // link.click()
            // document.body.removeChild(link)
          },
          add(){
            // 新增
            this.medicalDrawerVis = true;
            this.$refs.medicalForm.initAdd();
          },
          edit(row){
            this.medicalDrawerVis = true;
            this.$refs.medicalForm.load(row.id);
          },
            selectType(value){
              this.exportType = value
            },
            handleQuery() {
              this.queryForm.page = 1
              this.visible = false;
              this.getList()
            },
            handleReset() {
              this.$refs.queryForm.resetFields()
              this.queryForm.startCreateTime = moment().month(moment().month()).startOf('month').format('YYYY-MM-DD');
              this.queryForm.endCreateTime = moment().month(moment().month()).endOf('month').format('YYYY-MM-DD')
            },
            confirmExport() {
              if(!this.beforeQuery()){
                return;
              }
              this.isdeling = true
              if(this.exportType === '1' && this.multipleSelection.length === 0){
                einvAlert.warning('提示','请先勾选需要导出的发票',true,'确定')
                this.isdeling = false
                this.exportVis = false
                return
              }
              var ids ='';
              if (this.exportType === '1'){
                ids = this.multipleSelection.map(item => {
                    return item.id
                  }).join(',')
              }
              axios({
                method: 'get',
                url: '/api/einvoice/medical/export',
                responseType: 'blob',
                params: {
                  ids: this.exportType === '1'?ids:'',
                  companyId: this.getToken('companyId'),
                  ...this.queryForm,
                },
                headers: {
                  'Content-Type': "application/json;charset=utf-8",
                  'Authorization': this.getCookies('Authorization'),
                  'group' :process.env.VUE_APP_GROUP
                }
              }).then((res) => {
                let data = res.data
                let fileReader = new FileReader();
                fileReader.onload = function() {
                  try {
                    let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
                    if (jsonData.status == '40301') { // 接口返回的错误信息
                      einvAlert.error("错误",jsonData.message) // 弹出的提示信息
                    }
                  } catch (err) {   // 解析成对象失败，说明是正常的文件流
                    let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url;
                    var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
                    link.setAttribute('download', '医疗电票' + now + '.xls')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                  }
                };
                fileReader.readAsText(data)  // 注意别落掉此代码，可以将 Blob 或者 File 对象转根据特殊的编码格式转化为内容(字符串形式)
                this.isdeling = false
                this.exportVis = false
              }).catch(err=>{
                this.isdeling = false
              // einvAlert.error("提示",err)
              })
            },
            cancelDialog() {
               this.delDialog = false 
               this.batchDelDialog = false;
            },
            batchJkr() {
              if(this.multipleSelection.length === 0){
                this.cancelDialog()
                einvAlert.warning('提示','请先选择需要修改的发票记录',true,'确定')
                this.isdeling = false
                return
              }
              this.$prompt('请输入交款人', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(({ value }) => {
                this.$store.dispatch("BatchJkr",{
                  companyId: this.getToken('companyId'),
                  ids: this.multipleSelection.map(item => {
                      return item.id
                    }).join(','),
                  jkr:value
                }).then(res => {
                  if (res.success) {
                    einvAlert.success("提示",res.msg);
                    this.getList()
                  } else {
                    einvAlert.error("提示",res.msg);
                  }
                  this.isdeling = false
                }).catch(err => {
                  this.isdeling = false
                  einvAlert.error("提示",err);
                })
              }).catch(() => {
              });
            },
            batchConfirmDialog() {
              this.isdeling = true
              if(this.multipleSelection.length === 0){
                this.cancelDialog()
                einvAlert.warning('提示','请先选择发票',true,'确定')
                this.isdeling = false
                return
              }
              this.$store.dispatch("BatchDeleteMedical",{
                companyId: this.getToken('companyId'),
                ids: this.multipleSelection.map(item => {
                    return item.id
                  }).join(','),
              }).then(res => {
                this.batchDelDialog = false;
                if (res.success) {
                  einvAlert.success("提示","删除成功");
                  this.getList()
                } else {
                  einvAlert.error("提示",res.msg);
                }
                this.isdeling = false
              }).catch(err => {
                this.isdeling = false
                einvAlert.error("提示",err);
              })
            },
            confirmDialog() {
              this.isdeling = true
              this.$store.dispatch("DeleteMedical",{
                companyId: this.getToken('companyId'),
                id: this.selRow.id
              }).then(res => {
                if (res.success) {
                  einvAlert.success("提示","删除成功");
                  this.delDialog = false
                  this.getList()
                } else {
                  einvAlert.error("提示",res.msg);
                }
                this.isdeling = false
              }).catch(err => {
                this.isdeling = false
                einvAlert.error("提示",err);
              })
            },
            beforeQuery(){
              if(!this.getToken('companyId')){
                // einvAlert.warning("提示","请设置当前公司")
                this.$router.push('/home')
                // localStorage.setItem('sideLink', '/home')
                return false;
              }
              if(this.queryForm.invoiceNoStart !== '' && this.queryForm.invoiceNoEnd !== ''
                  && Number(this.queryForm.invoiceNoStart) > Number(this.queryForm.invoiceNoEnd)){
                einvAlert.warning("提示","起始发票号码不能大于结束发票号码")
                return false;
              }
              if (this.queryForm.startCreateTime  == null || !this.queryForm.startCreateTime) {
                einvAlert.warning("提示","请选择创建开始日期")
                return false;
              }
              if (this.queryForm.endCreateTime == null || !this.queryForm.endCreateTime) {
                einvAlert.warning("提示","请选择创建结束日期")
                return false;
              }
              return true;
            },
            getList() {
              if(!this.beforeQuery()){
                return;
              }
              this.$store.dispatch("PageMedical",
                  {
                    companyId: this.getToken('companyId'),
                    ...this.queryForm,
                  }
              ).then(res => {
                console.log(3+"-"+new Date().toLocaleString());
                if (res.success) {
                  this.isLoading = false;
                  this.tableData = res.data
                  this.total = res.totalCount
                  if(res.data.length>0){
                    this.isExport = true;
                  }
                  //当前页无数据时，page-1
                  if(res.data.length == '0' && res.totalCount>0 && this.queryForm.page>1){
                    this.queryForm.page = this.queryForm.page -1;
                  }
                } else {
                  this.isLoading = false;
                  einvAlert.error("提示",res.msg)
                }
                this.exportFrom = this.queryForm
              }).catch(err => {
                this.isLoading = false;
                this.isExport = true;
                // einvAlert.error("提示","服务运行异常")
              })
              console.log(4+"-"+new Date().toLocaleString());
            },
            // 切换表格分页
            changeSize(size) {
                this.queryForm.rows = size
                this.getList();
            },
            handleCurrentChange(current){
                this.queryForm.page = current
                this.getList();
            },
            delete(row){
              this.selRow = row;
              this.delDialog = true;
            },
            selection(val){
                this.multipleSelection = val;
            },
            imports(){
                this.importVis = true;
            },
            batchExport(){
              if(!this.isExport){
                //einvAlert.warning('提示','当前无数据，请检查',true,'确定')
                einvAlert.error("提示",'当前无数据，请检查')
                return
              }
              this.exportVis = true
            },
            exportCancel(){
              this.exportVis = false;
            },
            batchDelete(){
              this.batchDelDialog = true;
            },
            printPDF(){

            },
            importCancel(){
                this.importVis = false;
            }
        },
        watch:{
          'queryForm.startCreateTime':{
            handler(val){
              if (val == null || val==''){
                if (this.queryForm.endCreateTime == null){
                  this.queryTime = '请输入查询条件';
                } else{
                  this.queryTime = '至' + this.queryForm.endCreateTime;
                }
              } else{
                this.queryTime = this.queryForm.startCreateTime + '至' + this.queryForm.endCreateTime ;
              }
            },
            deep: true,
            immediate:true,
          },
          'queryForm.endCreateTime':{
            handler(val){
              if (val == null || val==''){
                if (this.queryForm.startCreateTime==null){
                  this.queryTime = '请输入查询条件';
                } else{
                  this.queryTime = this.queryForm.startCreateTime  + '至';
                }
              } else{
                this.queryTime = this.queryForm.startCreateTime  + '至' + this.queryForm.endCreateTime;
              }
            },
            deep: true,
            immediate:true,
          },
        },
        mounted() {
          this.queryTime = this.queryForm.startCreateTime + '至' + this.queryForm.endCreateTime
          this.handleQuery();
        }
    }
</script>
<style lang="less">
nav{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	.title{
		font-size: 15px;
		margin-right: 12px;
	}
  .tipsBtn {
      span {
        margin-right: 20px;
      }
    }
	.btn{
    margin-right: 10px;
		width: 120px;
	}
}
.invoice-account{
  .el-tag{
    min-width: 3rem !important;
  }
}
.single_query{
  .el-form-item__content {
    width: 60% !important;
  }
}
.range-query{
  .el-form-item__content {
    width: 85% !important;
  }
  .filter-item{
    width: 45%;
  }
}
.button_query{
  float: right;
  .el-form-item__content {
    width: 100% !important;
  }
}
</style>

<style lang="less" scoped>
  .tz {
    .el-form-item{
      margin-right: 0.2rem !important;
    }
  }
  .button{
    margin-right: 10px;
  }
  .batch_button{
    padding-top: 10px;
    margin-top: 15px;
    background: #ffffff;
    .btn1{
      margin-right: 10px;
      background: #409eff;
      border: 1px solid #409eff;
      border-radius: 8px;
      color: #ffffff;
      height: 40px;
      width: 120px;
    }
    .btn2{
      margin-right: 10px;
      background: #e8f5ff;
      border: 1px solid #409eff;
      border-radius: 8px;
      color: #409eff;
      height: 40px;
      width: 120px;
    }
    .btnBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 24px;
      .el-icon-question {
        color: #a7a7a7;
        font-size: 20px;
      }
      .el-icon-question:hover {
        color: #409eff;
        font-size: 20px;
      }

    }
  }
  .zhineng {
    position: relative;
  }
  .showAllCompany {
    position: relative;
    margin-left: 78%;
    margin-top: 0.7%;
  }
  .tip {
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    height: 175px;
    padding:17px 12px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: -3px 1px 10px 0px rgba(169,169,169,0.26);
    font-size: 14px;
    line-height: 24px;
    z-index: 99;
    p {
      margin: 0;

    }
  }

   .table {
    height: calc(100% - 3rem);
    //  padding: 24px 20px;
     padding-bottom: 3rem;
     background-color: #fff;
     .comp_table {
      // height: 600px;
      border: 1px solid #ebeef5;
     }
   }
   .answer {
     text-align: center;
     padding: 24px 0 14px;
     font-size: 16px;
   }
  .removeControl {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"]{
      -moz-appearance: textfield;
    }
   }

    /deep/.el-table__body-wrapper{
      height: 410px;
      overflow-y: auto;
    }
    /deep/ .el-table__fixed-body-wrapper {
        overflow-y: auto; // fixed的列也要超出滚动
        height: 400px;  // 出现滚动条需要比表格短10px
    }
</style>
